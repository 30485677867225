<!--
 * ======================================
 * 说明： 年级组长
 * 作者： Silence
 * 文件： gradeteacher.vue
 * 日期： 2023/8/2 0:08
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div class="h-full">
    <Scrollbar>
      <Title title="评价设置"/>
      <div class="m-4">
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-form-item label="选择学期">
            <el-select class="w-full" v-model="formInline.semester_id" @click="changeSemester">
              <el-option v-for="item in semesterOptions" :key="item.value" :label="item.semester_name"
                         :value="item.id"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择年级">
            <el-select class="w-full" v-model="formInline.grade">
              <el-option v-for="item in gradeOptions" :key="item.value" :label="item.label"
                         :value="item.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="handleLoadEvaluate">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div class="m-2" v-loading="loading">
          <div class="border-2 evaluate-setting-box">
            <el-row class="last-child-border-no border-b-2" v-for="(item,index) in dataSource" :key="index" :gutter="20">
              <tree-node v-model="scoreNums" :nodeKey="item.key" :index="index" :node="item"></tree-node>
            </el-row>
          </div>
          <el-button class="mt-2" type="success" @click="handleSave">保存</el-button>
<!--          <el-button v-if="lock === 1" class="mt-2" type="success" @click="handleUnlock">解锁</el-button>-->
<!--          <el-button v-else class="mt-2" type="success" @click="handleLock">锁定</el-button>-->
      </div>
    </Scrollbar>
  </div>
</template>
<script>
import treeNode from './components/treeNode.vue';
export default {
  name: '',
  components: {
    treeNode
  },
  props: {},
  data() {
    return {
      id:null,
      lock:0,
      result: [],
      scoreNums: {},
      loading:false,
      params:{},
      semesterOptions: [],
      gradeOptions:[],
      props: {
        multiple: true,
      },
      formInline: {
        semester_id: '',
        grade: '',
      },
      formData: {
        title: '',
        content: [],
        datetime: '',
        status: 0
      },

    }
  },
  computed: {
    dataSource() {
      let data = [];
      let  handleData = (array)=>{
        for ( const arrayKey in array ) {
          let item = array[arrayKey]
            if(item.length && !this.result.children){
              handleData(item)
                // console.log(handleData)
            }else{
              data.push(item);
            }
        }
      }
      if(this.result.length > 0 || !this.result.children){
        handleData(this.result);
      }
      return data;
    }
  },
  async mounted() {
    await this.handleLoadSemesterOptions()
    // await this.handleLoadGrade();
  },
  methods: {
    changeSemester(val) {
      console.log("选择学期",val)
      this.$cloud.get('/task/grade/list',{semesterId:val}).then(res => {
        console.log('res',res)
        this.gradeOptions = res
        if(res.length > 0) {
          this.formInline.grade = res[0].value
          this.handleLoadEvaluate()
        }
      })
    },
    // 获取年级
    async handleLoadSemesterOptions() {
      return await this.$cloud.page("semester/list", {
        size: 99999,
        page: 1,
      }).then(res => {
        this.semesterOptions = res.children;
        console.log('年级',res.children,res.children[0].id)
        this.formInline.semester_id = res.children[0].id;
        this.changeSemester(this.formInline.semester_id)
      })
    },

    async handleLoadEvaluate() {
      this.loading = true
      return await this.$cloud.get("evaluate/list",{
        semester_id: this.formInline.semester_id,
        grade: this.formInline.grade
      }).then(res => {
        this.result = res;
        //用于加载内容
        this.handleLoadTaskInfo();
      })
    },

    // 详情
    handleLoadTaskInfo() {
      let {semester_id,grade} = this.formInline;
      return this.$cloud.get("evaluate/detail", {
        semester_id,
        grade
      }).then(res => {
        this.loading = false
        if(res.id){
          this.id = res.id
          this.lock = res.lock
          this.params.grade = res.grade
          this.scoreNums = res.content
        }else{
          this.id = ''
          this.params.grade = this.formInline.grade
          this.scoreNums = {}
        }
      })
    },

    // async handleLoadGrade(){
    //    await this.$cloud.get("grade/get/now").then(res=>{
    //     this.params.grade= res
    //   })
    // },

    handleSave() {
        let api = "evaluate/save"
        let {semester_id} = this.formInline;
        this.params.semester_id = semester_id;
        this.params.content = this.scoreNums;
        if(this.id || this.id === 0){
          api = "evaluate/update"
          this.params.id = this.id
        }
        return this.$cloud.post(api, this.params).then(() => {
          this.$message.success('保存成功')
          // this.lock = 1
        })
    },
    handleUnlock() {
      console.log("解锁")
    },
    handleLock() {
      console.log("锁定")
    }
  },
}
</script>
<style lang="scss" scoped>
.last-child-border-no:last-child{
  //border-bottom: none;
}
.evaluate-setting-box{
  min-height: calc(100vh - 420px);
  overflow: hidden;
  overflow-y: auto;
}
</style>
