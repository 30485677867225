import { render, staticRenderFns } from "./evaluate.vue?vue&type=template&id=98b0f78a&scoped=true"
import script from "./evaluate.vue?vue&type=script&lang=js"
export * from "./evaluate.vue?vue&type=script&lang=js"
import style0 from "./evaluate.vue?vue&type=style&index=0&id=98b0f78a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "98b0f78a",
  null
  
)

export default component.exports