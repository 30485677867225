<!--
 * ======================================
 * 说明： 评价设置
 * 作者： Silence
 * 文件： treeNode.vue
 * 日期： 2023/8/20 3:17
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <div :class="`w-full h-full flex justify-start items-center ${index > 0?'border-t-2':''}`">
    <el-col :span="node.children ? 4 : 8" class=" py-2 text-center">{{ node.label }}</el-col>
    <el-col v-if="!node.children" :span="16" class="py-2 flex justify-start items-center">
<!--      {{ formData[nodeKey] }}-->
      <template v-if="node.type === 3">
        <el-input-number v-model="formData[nodeKey][node.key]" style="width: 180px" :min="1" :max="20"  placeholder="打分次数">
        </el-input-number>
        <label class="ml-2">次数</label>
      </template>
      <template v-if="node.type === 4">
        <el-input v-model="formData[nodeKey][node.key]" style="width: 180px" ></el-input>
      </template>
    </el-col>
    <el-col class="border-l-2" style="padding: 0 !important" :span="20" >
      <el-row :span="8" v-for="(child,i) in node.children" :key="child.key">
        <!-- 递归调用自身，渲染子节点 -->
        <tree-node v-model="formData" :node-key="nodeKey" :node="child" :index="i"></tree-node>
      </el-row>
    </el-col>
  </div>
</template>

<script>
export default {
  name: 'treeNode', // 设置组件名称，以便递归调用
  props: {
    node: Object, // 接收节点数据作为属性
    nodeKey:{
      type:[String,Number],
      default:null,
    },
    index: {
      type: Number,
      default: 0
    },
    value: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    formData: {
      get() {
        if(!Object.keys(this.value).includes(String(this.nodeKey))){
          this.$set(this.value,this.nodeKey,{})
        }
        // console.log('this.value:',this.value);
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    }
  },
  mounted() {
  },

  data() {
    return {
      number:0,
      types:[3,4]
    }
  },
  methods: {
  
  }
};
</script>

<style scoped>
/* Add your CSS styles here */
</style>
